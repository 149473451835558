import React from 'react';
import { GoogleLogin } from 'react-google-login';

const CLIENT_ID = '825172977028-qm1oau0q7ban9a3b98klhkapi4jvh97o.apps.googleusercontent.com';

function Login(props) {

  const onSuccess = (res) => {
    console.log("[Login Success]");
    console.log(res.profileObj);
    props.setImage(res.profileObj.imageUrl);
  }

  const onFailure = (res) => {
    console.log("[Login Failed]");
    console.log(res);
  }

  return (
    <div>
      <GoogleLogin
        clientId={CLIENT_ID}
        buttonText="Login"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={'single_host_origin'}
        isSignedIn={true}
      /> 
    </div>
  );
}

export default Login;
