import React, { useState } from 'react';
import Login from './Login'
import Logout from './Logout';

function App() {

  const [image, setImage] = useState("");

  return (
    <div>
      <img src={image}/>
      <Login setImage={(url) => setImage(url)}/>
      <Logout/>
    </div>
  );
}

export default App;
