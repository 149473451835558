import React from 'react';
import { GoogleLogout } from 'react-google-login';

const CLIENT_ID = '825172977028-qm1oau0q7ban9a3b98klhkapi4jvh97o.apps.googleusercontent.com';

function Logout() {

  const onSuccess = () => {
    console.log("[Logout Success]");
  }

  return (
    <div>
      <GoogleLogout
        clientId={CLIENT_ID}
        buttonText="Logout"
        onLogoutSuccess={onSuccess}
      /> 
    </div>
  );
}

export default Logout;
